/**
 * Copyright 2021 AnhNQ
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/************************Popup*************************/
.mapboxgl-popup-content {
    opacity: 0.8;
    background-color: #FFFFFF52;
    padding: 4px;
}

.mapboxgl-popup-tip {
    opacity: 0.8;
}

.popup {
    background-color: #d2d2d2;
    padding: 10px 10px 15px;
    border-radius: 3px;
}

.snow-observation-marker .mapboxgl-popup-content {
    padding: 0;
    background-color: transparent;
    opacity: 1;
    box-shadow: none;
}

.snow-observation-marker .mapboxgl-popup-tip {
    display: none;
}

.mapboxgl-popup-anchor-bottom .snow-observation-marker__icon {
    transform: rotate(180deg);
}

.snowplow-car-marker .mapboxgl-popup-content {
    padding: 0;
    background-color: white;
    opacity: 0.6;
    box-shadow: none;
    border: solid 2px #9e9e9e
}

.snowplow-car-marker__selected .mapboxgl-popup-content {
    border: solid 2px #FFD500
}

.snowplow-car-marker .mapboxgl-popup-tip {
    border-top-color: #9e9e9e;
    border-bottom-color: #9e9e9e;
    border-width: 8px;
    opacity: 0.6;
}

.snowplow-car-marker__selected .mapboxgl-popup-content {
    background-color: #00CAFF;
    opacity: 1;
}

.snowplow-car-marker__selected .mapboxgl-popup-tip {
    border-top-color: #FFD500;
    border-bottom-color: #FFD500;
    opacity: 1;
}

.react-player {
    background-color: #000;
}

.react-player video {
    display: block;
    background-color: #000;
}